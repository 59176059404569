const roleAccess = {
  admin: {
    permissions: {
      Counterparties: {
        read_all: true,
        read: true,
        read_all: true,
        create: true,
        edit: true,
        delete: true,
      },
      'Customer cargo list': {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
        assign_storage_cell: true,
        enter_invoice_nubmer: true,
        submit_closing_documents: true,
        ready_to_issue: true,
      },
      Orders: {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
        create_delivery: true,
      },
      Products: {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
      },
      'Received products': {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
      },
      Deliveries: {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
        scan_track_number: true,
        assign_storage_cell: true,
      },
      Shipments: {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
        scan_barcode: true,
        by_invoice_number: true,
        assign_storage_cell: true,
        ready_to_ship: false,
      },
      Storehouses: {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
      },
      Shelvings: {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
      },
      'Storage cells': {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
      },
      'Unidentified cargo list': {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
      },
      'Unidentified deliveries': {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
      },
    },
    fieldRestrictions: {
      Counterparties: {
        read: [
          'id',
          'name',
          'group_translate',
          'phone_number',
          'contact_person',
          'link_to_messenger',
          'responsible',
        ],
        create: [
          'name',
          'group_translate',
          'phone_number',
          'contact_person',
          'link_to_messenger',
          'responsible',
        ],
        edit: [
          'name',
          'group_translate',
          'phone_number',
          'contact_person',
          'link_to_messenger',
          'responsible',
        ],
        read_all: [
          'id',
          'name',
          'group_translate',
          'phone_number',
          'contact_person',
          'link_to_messenger',
        ],
      },
      Orders: {
        read: [
          'id',
          'client_name',
          'estimated_cost_logistics',
          'final_cost_logistics',
          'rate_cny_to_rub',
          'rate_usd_to_rub',
          'completed',
          'total_amount',
          'responsible',
          'co_executors',
        ],
        create: [
          'client_name',
          'estimated_cost_logistics',
          'rate_cny_to_rub',
          'rate_usd_to_rub',
          'responsible',
          'co_executors',
        ],
        edit: [
          'client_name',
          'estimated_cost_logistics',
          'rate_cny_to_rub',
          'rate_usd_to_rub',
          'responsible',
          'co_executors',
        ],
        read_all: [
          'id',
          'datetime_created',
          'client_name',
          'estimated_cost_logistics',
          'final_cost_logistics',
          'completed',
        ],
      },
      Products: {
        read: [
          'id',
          'name',
          'description',
          'order_id',
          'status',
          'link',
          'qty',
          'price',
          'currency',
          'supplier_name',
          'deliveries_id',
          'shipment_id',
          'customer_cargo_id',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'images',
          'qty_ordered_products',
          'qty_products_in_china',
          'qty_shipped_products',
          'qty_products_in_russia',
          'qty_issued_products',
          'datetime_created',
        ],
        read_all: [
          'id',
          'client_name',
          'order_id',
          'name',
          'delivery_qty_with_unit',
          'shipment_qty_with_unit',
          'customer_cargo_qty_with_unit',
          'description',
          'status_translate',
          'qty_with_unit',
          'price_and_currency',
          'storehouse_name',
          'storage_cell_name',
          'supplier_name',
          'qty_ordered_products',
          'qty_products_in_china',
          'qty_shipped_products',
          'qty_products_in_russia',
          'qty_issued_products',
          'datetime_created',
        ],
        create: [
          'name',
          'description',
          'order_id',
          'link',
          'qty',
          'price',
          'currency',
          'supplier_name',
          'responsible',
          'co_executors',
          'images',
        ],
        edit: [
          'name',
          'description',
          'status',
          'link',
          'qty',
          'price',
          'currency',
          'supplier_name',
          'deliveries_id',
          'shipment_id',
          'customer_cargo_id',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'images',
        ],
      },
      'Received products': {
        read: [
          'id',
          'name',
          'order_id',
          'status',
          'link',
          'delivery_qty_with_unit',
          'price',
          'currency',
          'supplier_name',
          'deliveries_id',
          'shipment_id',
          'customer_cargo_id',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'images',
        ],
        read_all: [
          'id',
          'client_name',
          'order_id',
          'name',
          'status_translate',
          'delivery_qty_with_unit',
          'price',
          'currency',
          'storehouse_name',
          'storage_cell_name',
          'supplier_name',
        ],
        edit: [
          'name',
          'status',
          'link',
          'qty',
          'price',
          'currency',
          'supplier_name',
          'deliveries_id',
          'shipment_id',
          'customer_cargo_id',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'images',
        ],
      },
      Deliveries: {
        read: [
          'id',
          'order_id',
          'client_name',
          'track_number',
          'status_tranclate',
          'total_amount',
          'media_content_acceptance',
          'responsible',
          'co_executors',
        ],
        read_all: [
          'id',
          'order_id',
          'track_number',
          'status_translate',
          'add_information',
        ],
        create: [
          'order_id',
          'supplier',
          'products',
          'track_number',
          'responsible',
          'co_executors',
        ],
        edit: [
          'order_id',
          'supplier',
          'products',
          'track_number',
          'status_tranclate',
          'responsible',
          'co_executors',
          'media_content_acceptance',
        ],
      },
      Shipments: {
        read_all: [
          'id',
          'client_name',
          'type_logistics_translate',
          'status_translate',
          'invoice_number',
          'weight_in_kg',
          'volume_in_m3',
          'number_of_seats',
          'responsible_names',
          'add_information',
        ],
        read: [
          'id',
          'order_id',
          'type_logistics_translate',
          'packing_translate',
          'status_translate',
          'total_amount',
          'weight_in_kg',
          'volume_in_m3',
          'number_of_seats',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'qr_code',
          'logistics_documents',
          'media_content',
        ],
        create: [
          'order_id',
          'products',
          'packing_translate',
          'weight_in_kg',
          'volume_in_m3',
          'number_of_seats',
          'responsible',
          'co_executors',
        ],
        edit: [
          'order_id',
          'product',
          'status_translate',
          'packing_translate',
          'weight_in_kg',
          'volume_in_m3',
          'number_of_seats',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'logistics_documents',
          'media_content',
        ],
      },
      'Customer cargo list': {
        read_all: [
          'id',
          'client_name',
          'content',
          'status_translate',
          'invoice_number',
          'responsible_names',
          'add_information',
        ],
        read: [
          'id',
          'order_id',
          'shipment_id',
          'content',
          'status_translate',
          'total_amount',
          'weight_in_kg',
          'volume_in_m3',
          'storehouse_name',
          'storage_cell_name',
          'invoice_documents',
          'media_content',
          'responsible',
          'co_executors',
        ],
        create: ['shipment_id', 'responsible', 'co_executors'],
        edit: [
          // 'shipment_id',
          'status_translate',
          'weight_in_kg',
          'volume_in_m3',
          'content',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'media_content',
          'invoice_documents',
        ],
      },
      Storehouses: {
        read_all: ['id', 'name', 'address'],
        read: ['id', 'name', 'address'],
        create: ['name', 'address'],
        edit: ['name', 'address'],
      },
      Shelvings: {
        read_all: [
          'id',
          'name',
          'storehouse_name',
          'storage_cell_free',
          'storage_cell_not_free',
        ],
        read: ['id', 'name', 'storehouse_name'],
        create: ['name', 'storehouse_name'],
        edit: ['name', 'storehouse_name'],
      },
      'Storage cells': {
        read_all: ['id', 'number', 'shelving_name', 'free_name'],
        read: ['id', 'number', 'shelving_name', 'free_name'],
        create: ['number', 'shelving_name'],
        edit: ['number', 'shelving_name', 'free_name'],
      },
      'Unidentified cargo list': {
        read_all: ['id', 'comment', 'invoice_number', 'status_translate'],
        read: [
          'id',
          'status_translate',
          'comment',
          'invoice_number',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'media_content_acceptance',
        ],
        create: [
          'comment',
          'invoice_number',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'media_content_acceptance',
        ],
        edit: [
          'status_translate',
          'comment',
          'invoice_number',
          'storehouse_name',
          'storage_cell_name',
          'media_content_acceptance',
          'responsible',
        ],
      },
      'Unidentified deliveries': {
        read_all: [
          'id',
          'track_number',
          'comment',
          'status_translate',
          'total_weight_in_kg',
          'total_volume_in_m3',
          'actual_content',
        ],
        read: [
          'id',
          'track_number',
          'status_translate',
          'total_weight_in_kg',
          'total_volume_in_m3',
          'actual_content',
          'media_content_acceptance',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
        ],
        create: [
          'track_number',
          'total_weight_in_kg',
          'total_volume_in_m3',
          'actual_content',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'media_content_acceptance',
        ],
        edit: [
          'track_number',
          'status_translate',
          'total_weight_in_kg',
          'total_volume_in_m3',
          'actual_content',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'media_content_acceptance',
        ],
      },
    },
    fieldFilters: {
      Counterparties: [
        'name',
        'group',
        'phone_number',
        'contact_person',
        'link_to_messenger',
        'responsible',
      ],
      Orders: [
        'client_name',
        'estimated_cost_logistics',
        'final_cost_logistics',
        'rate_cny_to_rub',
        'rate_usd_to_rub',
        'completed',
        'responsible',
        'co_executors',
      ],
      Products: [
        'name',
        'description',
        'order_id',
        'status',
        'link',
        'qty',
        'price',
        'currency',
        'supplier_name',
        'deliveries_id',
        'shipment_id',
        'customer_cargo_id',
        'storehouse_name',
        'storage_cell_name',
        'responsible',
        'co_executors',
        'qty_ordered_products',
        'qty_products_in_china',
        'qty_shipped_products',
        'qty_products_in_russia',
        'qty_issued_products',
      ],
      'Received products': [
        'name',
        'order_id',
        'status',
        'link',
        'delivery_qty',
        'price',
        'currency',
        'supplier_name',
        'deliveries_id',
        'shipment_id',
        'customer_cargo_id',
        'storehouse_name',
        'storage_cell_name',
        'responsible',
        'co_executors',
        'images',
      ],
      Deliveries: [
        'order_id',
        'client_name',
        'track_number',
        'status',
        'total_amount',
        'responsible',
        'co_executors',
      ],
      Shipments: [
        'client_name',
        'type_logistics',
        'status_translate',
        'invoice_number',
        'weight_in_kg',
        'volume_in_m3',
        'number_of_seats',
        'responsible',
      ],
      'Customer cargo list': [
        'client_name',
        'content',
        'status',
        'invoice_number',
        'responsible',
      ],
      Storehouses: ['name', 'address'],
      Shelvings: [
        'name',
        'storehouse_name',
        'storage_cell_free',
        'storage_cell_not_free',
      ],
      'Storage cells': ['number', 'shelving_name', 'free'],
      'Unidentified cargo list': ['comment', 'invoice_number', 'status'],
      'Unidentified deliveries': [
        'track_number',
        'comment',
        'status',
        'total_weight_in_kg',
        'total_volume_in_m3',
        'actual_content',
      ],
    },
  },
  client_realtions_manager: {
    permissions: {
      Counterparties: {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
      },
      Orders: {
        read: true,
        read_all: true,
        create: true,
        create_delivery: false,
        edit: true,
        delete: true,
      },
      Products: {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
      },
      Deliveries: {
        read_all: true,
        read: true,
      },
      Shipments: {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
        assign_storage_cell: false,
      },
      'Customer cargo list': {
        read_all: true,
        read: true,
        submit_closing_documents: true,
      },
    },
    fieldRestrictions: {
      Counterparties: {
        read: [
          'id',
          'name',
          'group_translate',
          'phone_number',
          'contact_person',
          'link_to_messenger',
          'responsible',
        ],
        create: [
          'name',
          'group_translate',
          'phone_number',
          'contact_person',
          'link_to_messenger',
          'responsible',
        ],
        edit: [
          'name',
          'group_translate',
          'phone_number',
          'contact_person',
          'link_to_messenger',
          'responsible',
        ],
        read_all: [
          'id',
          'name',
          'group_translate',
          'phone_number',
          'contact_person',
          'link_to_messenger',
        ],
      },
      Orders: {
        read: [
          'id',
          'client_name',
          'estimated_cost_logistics',
          'final_cost_logistics',
          'rate_cny_to_rub',
          'rate_usd_to_rub',
          'completed',
          'total_amount',
          'responsible',
          'co_executors',
        ],
        create: [
          'client_name',
          'estimated_cost_logistics',
          'rate_cny_to_rub',
          'rate_usd_to_rub',
          'responsible',
          'co_executors',
        ],
        edit: [
          'client_name',
          'estimated_cost_logistics',
          'rate_cny_to_rub',
          'rate_usd_to_rub',
          'responsible',
          'co_executors',
        ],
        read_all: [
          'id',
          'datetime_created',
          'client_name',
          'estimated_cost_logistics',
          'final_cost_logistics',
          'completed',
        ],
      },
      Products: {
        read: [
          'id',
          'name',
          'description',
          'order_id',
          'status',
          // 'link',
          'qty',
          'price',
          'currency',
          'supplier_name',
          'deliveries_id',
          'shipment_id',
          'customer_cargo_id',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'images',
          'qty_ordered_products',
          'qty_products_in_china',
          'qty_shipped_products',
          'qty_products_in_russia',
          'qty_issued_products',
        ],
        read_all: [
          'id',
          'client_name',
          'name',
          'status_translate',
          'qty_with_unit',
          'price_and_currency',
          'qty_ordered_products',
          'qty_products_in_china',
          'qty_shipped_products',
          'qty_products_in_russia',
          'qty_issued_products',
          'delivery_qty_with_unit',
          'shipment_qty_with_unit',
          'customer_cargo_qty_with_unit',
          'supplier_name',
        ],
        create: [
          'name',
          'description',
          'order_id',
          'qty',
          'responsible',
          'co_executors',
          'images',
        ],
        edit: [
          'name',
          'description',
          'qty',
          'responsible',
          'co_executors',
          'images',
        ],
      },
      Deliveries: {
        read: [
          'id',
          'order_id',
          'client_name',
          'track_number',
          'status_tranclate',
          'total_amount',
          'media_content_acceptance',
          'responsible',
          'co_executors',
        ],
        read_all: [
          'id',
          'order_id',
          'track_number',
          'status_translate',
          'add_information',
        ],
      },
      Shipments: {
        read_all: [
          'id',
          'client_name',
          'type_logistics_translate',
          'status_translate',
          'invoice_number',
          'weight_in_kg',
          'volume_in_m3',
          'number_of_seats',
          'responsible_names',
        ],
        read: [
          'id',
          'order_id',
          'type_logistics_translate',
          'packing_translate',
          'status_translate',
          'total_amount',
          'weight_in_kg',
          'volume_in_m3',
          'number_of_seats',
          'responsible',
          'co_executors',
          'qr_code',
          'logistics_documents',
          'media_content',
        ],
        create: [
          'order_id',
          'products',
          'packing_translate',
          'responsible',
          'co_executors',
        ],
        edit: [
          'order_id',
          'product',
          'packing_translate',
          'responsible',
          'co_executors',
        ],
      },
      'Customer cargo list': {
        read_all: [
          'id',
          'client_name',
          'content',
          'status_translate',
          'invoice_number',
          'responsible_names',
          'add_information',
        ],
        read: [
          'id',
          'order_id',
          'shipment_id',
          'content',
          'status_translate',
          'total_amount',
          'weight_in_kg',
          'volume_in_m3',
          'storehouse_name',
          'storage_cell_name',
          'invoice_documents',
          'media_content',
          'responsible',
          'co_executors',
        ],
      },
    },
    fieldFilters: {
      Counterparties: [
        'name',
        'group',
        'phone_number',
        'contact_person',
        'link_to_messenger',
        'responsible',
      ],
      Orders: [
        'client_name',
        'estimated_cost_logistics',
        'final_cost_logistics',
        'rate_cny_to_rub',
        'rate_usd_to_rub',
        'completed',
        'responsible',
        'co_executors',
      ],
      Products: [
        'name',
        'description',
        'order_id',
        'status',
        'link',
        'qty',
        'price',
        'currency',
        'supplier_name',
        'deliveries_id',
        'shipment_id',
        'customer_cargo_id',
        'storehouse_name',
        'storage_cell_name',
        'responsible',
        'co_executors',
        'qty_ordered_products',
        'qty_products_in_china',
        'qty_shipped_products',
        'qty_products_in_russia',
        'qty_issued_products',
      ],
      'Received products': [
        'name',
        'order_id',
        'status',
        'link',
        'delivery_qty',
        'price',
        'currency',
        'supplier_name',
        'deliveries_id',
        'shipment_id',
        'customer_cargo_id',
        'storehouse_name',
        'storage_cell_name',
        'responsible',
        'co_executors',
        'images',
      ],
      Deliveries: [
        'order_id',
        'client_name',
        'track_number',
        'status',
        'total_amount',
        'responsible',
        'co_executors',
      ],
      Shipments: [
        'client_name',
        'type_logistics',
        'status_translate',
        'invoice_number',
        'weight_in_kg',
        'volume_in_m3',
        'number_of_seats',
        'responsible',
      ],
      'Customer cargo list': [
        'client_name',
        'content',
        'status',
        'invoice_number',
        'responsible',
      ],
      Storehouses: ['name', 'address'],
      Shelvings: [
        'name',
        'storehouse_name',
        'storage_cell_free',
        'storage_cell_not_free',
      ],
      'Storage cells': ['number', 'shelving_name', 'free'],
      'Unidentified cargo list': ['comment', 'invoice_number', 'status'],
      'Unidentified deliveries': [
        'track_number',
        'comment',
        'status',
        'total_weight_in_kg',
        'total_volume_in_m3',
        'actual_content',
      ],
    },
  },
  supplier_realtions_manager: {
    permissions: {
      Counterparties: {
        read_all: true,
        read: true,
        read_all: true,
        create: true,
        edit: true,
        delete: true,
      },
      Orders: {
        read: true,
        read_all: true,
        create_delivery: true,
      },
      Products: {
        read_all: true,
        read: true,
        edit: true,
      },
      Deliveries: {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
      },
    },
    fieldRestrictions: {
      Counterparties: {
        read: [
          'id',
          'name',
          'group_translate',
          'phone_number',
          'contact_person',
          'link_to_messenger',
          'responsible',
        ],
        create: [
          'name',
          'group_translate',
          'phone_number',
          'contact_person',
          'link_to_messenger',
          'responsible',
        ],
        edit: [
          'name',
          'group_translate',
          'phone_number',
          'contact_person',
          'link_to_messenger',
          'responsible',
        ],
        read_all: [
          'id',
          'name',
          'group_translate',
          'phone_number',
          'contact_person',
          'link_to_messenger',
        ],
      },
      Orders: {
        read_all: [
          'id',
          'datetime_created',
          'client_name',
          'estimated_cost_logistics',
          'final_cost_logistics',
          'completed',
        ],
        read: [
          'id',
          'client_name',
          'estimated_cost_logistics',
          'rate_cny_to_rub',
          'rate_usd_to_rub',
          'completed',
          'total_amount',
          'responsible',
          'co_executors',
        ],
      },
      Products: {
        read: [
          'id',
          'name',
          'description',
          'order_id',
          'status',
          'link',
          'qty',
          'price',
          'currency',
          'supplier_name',
          'deliveries_id',
          'shipment_id',
          'customer_cargo_id',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'images',
          'qty_ordered_products',
          'qty_products_in_china',
        ],
        read_all: [
          'id',
          'client_name',
          'order_id',
          'name',
          'status_translate',
          'qty_with_unit',
          'price_and_currency',
          'delivery_qty_with_unit',
          'supplier_name',
        ],
        edit: [
          'link',
          'price',
          'currency',
          'supplier_id',
          'deliveries_id',
          'images',
        ],
      },
      Deliveries: {
        read: [
          'id',
          'order_id',
          'client_name',
          'track_number',
          'status_tranclate',
          'total_amount',
          'media_content_acceptance',
          'responsible',
          'co_executors',
        ],
        read_all: [
          'id',
          'order_id',
          'track_number',
          'status_translate',
          'add_information',
        ],
        create: [
          'order_id',
          'supplier',
          'products',
          'track_number',
          'responsible',
          'co_executors',
        ],
        edit: [
          'order_id',
          'supplier',
          'products',
          'track_number',
          'status_tranclate',
          'responsible',
          'co_executors',
          'media_content_acceptance',
        ],
      },
    },
    fieldFilters: {
      Counterparties: [
        'name',
        'group',
        'phone_number',
        'contact_person',
        'link_to_messenger',
        'responsible',
      ],
      Orders: [
        'client_name',
        'estimated_cost_logistics',
        'final_cost_logistics',
        'rate_cny_to_rub',
        'rate_usd_to_rub',
        'completed',
        'responsible',
        'co_executors',
      ],
      Products: [
        'name',
        'description',
        'order_id',
        'status',
        'link',
        'qty',
        'price',
        'currency',
        'supplier_name',
        'deliveries_id',
        'shipment_id',
        'customer_cargo_id',
        'storehouse_name',
        'storage_cell_name',
        'responsible',
        'co_executors',
        'qty_ordered_products',
        'qty_products_in_china',
        'qty_shipped_products',
        'qty_products_in_russia',
        'qty_issued_products',
      ],
      'Received products': [
        'name',
        'order_id',
        'status',
        'link',
        'delivery_qty',
        'price',
        'currency',
        'supplier_name',
        'deliveries_id',
        'shipment_id',
        'customer_cargo_id',
        'storehouse_name',
        'storage_cell_name',
        'responsible',
        'co_executors',
        'images',
      ],
      Deliveries: [
        'order_id',
        'client_name',
        'track_number',
        'status',
        'total_amount',
        'responsible',
        'co_executors',
      ],
      Shipments: [
        'client_name',
        'type_logistics',
        'status_translate',
        'invoice_number',
        'weight_in_kg',
        'volume_in_m3',
        'number_of_seats',
        'responsible',
      ],
      'Customer cargo list': [
        'client_name',
        'content',
        'status',
        'invoice_number',
        'responsible',
      ],
      Storehouses: ['name', 'address'],
      Shelvings: [
        'name',
        'storehouse_name',
        'storage_cell_free',
        'storage_cell_not_free',
      ],
      'Storage cells': ['number', 'shelving_name', 'free'],
      'Unidentified cargo list': ['comment', 'invoice_number', 'status'],
      'Unidentified deliveries': [
        'track_number',
        'comment',
        'status',
        'total_weight_in_kg',
        'total_volume_in_m3',
        'actual_content',
      ],
    },
  },
  chinese_storekeeper: {
    permissions: {
      Products: {
        read: true,
      },
      Deliveries: {
        read_all: true,
        read: true,
        scan_track_number: true,
        assign_storage_cell: true,
      },
      Storehouses: {
        read: true,
      },
      Shelvings: {
        read: true,
      },
      'Storage cells': {
        read: true,
      },
      'Unidentified deliveries': {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
      },
    },
    fieldRestrictions: {
      Orders: {
        read: [
          'id',
          'client_name',
          'estimated_cost_logistics',
          'final_cost_logistics',
          'rate_cny_to_rub',
          'rate_usd_to_rub',
          'completed',
          'total_amount',
          'responsible',
          'co_executors',
        ],
      },
      Products: {
        read: [
          'id',
          'name',
          'description',
          'order_id',
          'status',
          // 'link',
          'qty',
          'price',
          'currency',
          'supplier_name',
          'deliveries_id',
          'shipment_id',
          'customer_cargo_id',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'images',
          'qty_shipped_products',
        ],
        read_all: [
          'id',
          'client_name',
          'order_id',
          'name',
          'status_translate',
          'qty_with_unit',
          'delivery_qty_with_unit',
          'shipment_qty_with_unit',
          'price',
          'currency',
          'storehouse_name',
          'storage_cell_name',
          'supplier_name',
        ],
      },
      Deliveries: {
        read: [
          'id',
          'order_id',
          'client_name',
          'track_number',
          'status_tranclate',
          'total_amount',
          'media_content_acceptance',
          'responsible',
          'co_executors',
        ],
        read_all: [
          'id',
          'order_id',
          'track_number',
          'status_translate',
          'add_information',
        ],
      },
      Storehouses: {
        read: ['id', 'name', 'address'],
      },
      Shelvings: {
        read_all: [
          'id',
          'name',
          'storehouse_name',
          'storage_cell_free',
          'storage_cell_not_free',
        ],
        read: ['id', 'name', 'storehouse_name'],
      },
      'Storage cells': {
        read_all: ['id', 'number', 'shelving_name', 'free_name'],
        read: ['id', 'number', 'shelving_name', 'free_name'],
      },
      'Unidentified deliveries': {
        read_all: [
          'id',
          'track_number',
          'comment',
          'status_translate',
          'total_weight_in_kg',
          'total_volume_in_m3',
          'actual_content',
        ],
        read: [
          'id',
          'track_number',
          'status_translate',
          'total_weight_in_kg',
          'total_volume_in_m3',
          'actual_content',
          'media_content_acceptance',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
        ],
        create: [
          'track_number',
          'total_weight_in_kg',
          'total_volume_in_m3',
          'actual_content',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'media_content_acceptance',
        ],
        edit: [
          'track_number',
          'status_translate',
          'total_weight_in_kg',
          'total_volume_in_m3',
          'actual_content',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'media_content_acceptance',
        ],
      },
    },
    fieldFilters: {
      Counterparties: [
        'name',
        'group',
        'phone_number',
        'contact_person',
        'link_to_messenger',
        'responsible',
      ],
      Orders: [
        'client_name',
        'estimated_cost_logistics',
        'final_cost_logistics',
        'rate_cny_to_rub',
        'rate_usd_to_rub',
        'completed',
        'responsible',
        'co_executors',
      ],
      Products: [
        'name',
        'description',
        'order_id',
        'status',
        'link',
        'qty',
        'price',
        'currency',
        'supplier_name',
        'deliveries_id',
        'shipment_id',
        'customer_cargo_id',
        'storehouse_name',
        'storage_cell_name',
        'responsible',
        'co_executors',
        'qty_ordered_products',
        'qty_products_in_china',
        'qty_shipped_products',
        'qty_products_in_russia',
        'qty_issued_products',
      ],
      'Received products': [
        'name',
        'order_id',
        'status',
        'link',
        'delivery_qty',
        'price',
        'currency',
        'supplier_name',
        'deliveries_id',
        'shipment_id',
        'customer_cargo_id',
        'storehouse_name',
        'storage_cell_name',
        'responsible',
        'co_executors',
        'images',
      ],
      Deliveries: [
        'order_id',
        'client_name',
        'track_number',
        'status',
        'total_amount',
        'responsible',
        'co_executors',
      ],
      Shipments: [
        'client_name',
        'type_logistics',
        'status_translate',
        'invoice_number',
        'weight_in_kg',
        'volume_in_m3',
        'number_of_seats',
        'responsible',
      ],
      'Customer cargo list': [
        'client_name',
        'content',
        'status',
        'invoice_number',
        'responsible',
      ],
      Storehouses: ['name', 'address'],
      Shelvings: [
        'name',
        'storehouse_name',
        'storage_cell_free',
        'storage_cell_not_free',
      ],
      'Storage cells': ['number', 'shelving_name', 'free'],
      'Unidentified cargo list': ['comment', 'invoice_number', 'status'],
      'Unidentified deliveries': [
        'track_number',
        'comment',
        'status',
        'total_weight_in_kg',
        'total_volume_in_m3',
        'actual_content',
      ],
    },
  },
  chinese_storekeeper_manager: {
    permissions: {
      Products: {
        read: true,
      },
      'Received products': {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
      },
      Deliveries: {
        read_all: true,
        read: true,
        scan_track_number: true,
        assign_storage_cell: true,
      },
      Storehouses: {
        read: true,
      },
      Shelvings: {
        read: true,
      },
      'Storage cells': {
        read: true,
      },
      'Unidentified deliveries': {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
      },
      Shipments: {
        read_all: true,
        read: true,
        assign_storage_cell: false,
      },
    },
    fieldRestrictions: {
      Orders: {
        read: [
          'id',
          'client_name',
          'estimated_cost_logistics',
          'final_cost_logistics',
          'rate_cny_to_rub',
          'rate_usd_to_rub',
          'completed',
          'total_amount',
          'responsible',
          'co_executors',
        ],
      },
      Products: {
        read: [
          'id',
          'name',
          'description',
          'order_id',
          'status',
          // 'link',
          'qty',
          'price',
          'currency',
          'supplier_name',
          'deliveries_id',
          'shipment_id',
          'customer_cargo_id',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'images',
          'qty_ordered_products',
          'qty_products_in_china',
          'qty_shipped_products',
        ],
        read_all: [
          'id',
          'client_name',
          'order_id',
          'name',
          'status_translate',
          'qty_with_unit',
          'delivery_qty_with_unit',
          'shipment_qty_with_unit',
          'price',
          'currency',
          'storehouse_name',
          'storage_cell_name',
          'supplier_name',
        ],
      },
      'Received products': {
        read: [
          'id',
          'name',
          'order_id',
          'status',
          'link',
          'delivery_qty_with_unit',
          'price',
          'currency',
          'supplier_name',
          'deliveries_id',
          'shipment_id',
          'customer_cargo_id',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'images',
        ],
        read_all: [
          'id',
          'client_name',
          'order_id',
          'name',
          'status_translate',
          'delivery_qty_with_unit',
          'price',
          'currency',
          'storehouse_name',
          'storage_cell_name',
          'supplier_name',
        ],
        edit: [
          'name',
          'status',
          'link',
          'qty',
          'price',
          'currency',
          'supplier_name',
          'deliveries_id',
          'shipment_id',
          'customer_cargo_id',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'images',
        ],
      },
      Deliveries: {
        read: [
          'id',
          'order_id',
          'client_name',
          'track_number',
          'status_tranclate',
          'total_amount',
          'media_content_acceptance',
          'responsible',
          'co_executors',
        ],
        read_all: [
          'id',
          'order_id',
          'track_number',
          'status_translate',
          'add_information',
        ],
      },
      Storehouses: {
        read: ['id', 'name', 'address'],
      },
      Shelvings: {
        read_all: [
          'id',
          'name',
          'storehouse_name',
          'storage_cell_free',
          'storage_cell_not_free',
        ],
        read: ['id', 'name', 'storehouse_name'],
      },
      'Storage cells': {
        read_all: ['id', 'number', 'shelving_name', 'free_name'],
        read: ['id', 'number', 'shelving_name', 'free_name'],
      },
      'Unidentified deliveries': {
        read_all: [
          'id',
          'track_number',
          'comment',
          'status_translate',
          'total_weight_in_kg',
          'total_volume_in_m3',
          'actual_content',
        ],
        read: [
          'id',
          'track_number',
          'status_translate',
          'total_weight_in_kg',
          'total_volume_in_m3',
          'actual_content',
          'media_content_acceptance',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
        ],
        create: [
          'track_number',
          'total_weight_in_kg',
          'total_volume_in_m3',
          'actual_content',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'media_content_acceptance',
        ],
        edit: [
          'track_number',
          'status_translate',
          'total_weight_in_kg',
          'total_volume_in_m3',
          'actual_content',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'media_content_acceptance',
        ],
      },
      Shipments: {
        read_all: [
          'id',
          'client_name',
          'type_logistics_translate',
          'status_translate',
          'invoice_number',
          'weight_in_kg',
          'volume_in_m3',
          'number_of_seats',
          'responsible_names',
          'add_information',
        ],
        read: [
          'id',
          'order_id',
          'type_logistics_translate',
          'packing_translate',
          'status_translate',
          'total_amount',
          'weight_in_kg',
          'volume_in_m3',
          'number_of_seats',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'qr_code',
          'logistics_documents',
          'media_content',
        ],
        edit: [
          'weight_in_kg',
          'volume_in_m3',
          'storehouse_name',
          'storage_cell_name',
          'logistics_documents',
          'media_content',
        ],
      },
    },
    fieldFilters: {
      Counterparties: [
        'name',
        'group',
        'phone_number',
        'contact_person',
        'link_to_messenger',
        'responsible',
      ],
      Orders: [
        'client_name',
        'estimated_cost_logistics',
        'final_cost_logistics',
        'rate_cny_to_rub',
        'rate_usd_to_rub',
        'completed',
        'responsible',
        'co_executors',
      ],
      Products: [
        'name',
        'description',
        'order_id',
        'status',
        'link',
        'qty',
        'price',
        'currency',
        'supplier_name',
        'deliveries_id',
        'shipment_id',
        'customer_cargo_id',
        'storehouse_name',
        'storage_cell_name',
        'responsible',
        'co_executors',
        'qty_ordered_products',
        'qty_products_in_china',
        'qty_shipped_products',
        'qty_products_in_russia',
        'qty_issued_products',
      ],
      'Received products': [
        'name',
        'order_id',
        'status',
        'link',
        'delivery_qty',
        'price',
        'currency',
        'supplier_name',
        'deliveries_id',
        'shipment_id',
        'customer_cargo_id',
        'storehouse_name',
        'storage_cell_name',
        'responsible',
        'co_executors',
        'images',
      ],
      Deliveries: [
        'order_id',
        'client_name',
        'track_number',
        'status',
        'total_amount',
        'responsible',
        'co_executors',
      ],
      Shipments: [
        'client_name',
        'type_logistics',
        'status_translate',
        'invoice_number',
        'weight_in_kg',
        'volume_in_m3',
        'number_of_seats',
        'responsible',
      ],
      'Customer cargo list': [
        'client_name',
        'content',
        'status',
        'invoice_number',
        'responsible',
      ],
      Storehouses: ['name', 'address'],
      Shelvings: [
        'name',
        'storehouse_name',
        'storage_cell_free',
        'storage_cell_not_free',
      ],
      'Storage cells': ['number', 'shelving_name', 'free'],
      'Unidentified cargo list': ['comment', 'invoice_number', 'status'],
      'Unidentified deliveries': [
        'track_number',
        'comment',
        'status',
        'total_weight_in_kg',
        'total_volume_in_m3',
        'actual_content',
      ],
    },
  },
  moscow_storekeeper_manager: {
    permissions: {
      Products: {
        read: true,
      },
      Orders: {
        read: true,
      },
      Shipments: {
        read: true,
        scan_barcode: true,
        by_invoice_number: true,
        assign_storage_cell: false,
      },
      'Customer cargo list': {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
        enter_invoice_nubmer: true,
        assign_storage_cell: true,
        // submit_closing_documents: true,
        ready_to_issue: true,
        issue_to_the_client: true,
      },
      'Unidentified cargo list': {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
      },
    },
    fieldRestrictions: {
      Orders: {
        read: [
          'id',
          'client_name',
          'estimated_cost_logistics',
          'final_cost_logistics',
          'rate_cny_to_rub',
          'rate_usd_to_rub',
          'completed',
          'total_amount',
          'responsible',
          'co_executors',
        ],
      },
      Products: {
        read_all: [
          'id',
          'client_name',
          'order_id',
          'name',
          'status_translate',
          'qty_with_unit',
          'price',
          'currency',
          'storehouse_name',
          'storage_cell_name',
          'supplier_name',
          'qty_products_in_russia',
          'qty_issued_products',
        ],
        read: [
          'id',
          'name',
          'description',
          'order_id',
          'status',
          // 'link',
          'qty_with_unit',
          'shipment_qty_with_unit',
          'customer_cargo_qty_with_unit',
          'price',
          'currency',
          'supplier_name',
          'deliveries_id',
          'shipment_id',
          'customer_cargo_id',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'images',
        ],
      },
      Shipments: {
        read: [
          'id',
          'order_id',
          'type_logistics_translate',
          'packing_translate',
          'status_translate',
          'total_amount',
          'weight_in_kg',
          'volume_in_m3',
          'number_of_seats',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'qr_code',
          'logistics_documents',
          'media_content',
        ],
      },
      'Customer cargo list': {
        read_all: [
          'id',
          'client_name',
          'content',
          'status_translate',
          'invoice_number',
          'responsible_names',
          'add_information',
        ],
        read: [
          'id',
          'order_id',
          'shipment_id',
          'content',
          'status_translate',
          'total_amount',
          'weight_in_kg',
          'volume_in_m3',
          'storehouse_name',
          'storage_cell_name',
          'invoice_documents',
          'media_content',
          'responsible',
          'co_executors',
        ],
        create: ['shipment_id', 'responsible', 'co_executors'],
        edit: [
          // 'shipment_id',
          'status_translate',
          'weight_in_kg',
          'volume_in_m3',
          'content',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'media_content',
          'invoice_documents',
        ],
      },
      'Unidentified cargo list': {
        read_all: ['id', 'comment', 'invoice_number', 'status_translate'],
        read: [
          'id',
          'comment',
          'invoice_number',
          'status_translate',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'media_content_acceptance',
        ],
        create: [
          'comment',
          'invoice_number',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'media_content_acceptance',
        ],
        edit: [
          'comment',
          'invoice_number',
          'status_translate',
          'storehouse_name',
          'storage_cell_name',
          'media_content_acceptance',
          'responsible',
        ],
      },
    },
    fieldFilters: {
      Counterparties: [
        'name',
        'group',
        'phone_number',
        'contact_person',
        'link_to_messenger',
        'responsible',
      ],
      Orders: [
        'client_name',
        'estimated_cost_logistics',
        'final_cost_logistics',
        'rate_cny_to_rub',
        'rate_usd_to_rub',
        'completed',
        'responsible',
        'co_executors',
      ],
      Products: [
        'name',
        'description',
        'order_id',
        'status',
        'link',
        'qty',
        'price',
        'currency',
        'supplier_name',
        'deliveries_id',
        'shipment_id',
        'customer_cargo_id',
        'storehouse_name',
        'storage_cell_name',
        'responsible',
        'co_executors',
        'qty_ordered_products',
        'qty_products_in_china',
        'qty_shipped_products',
        'qty_products_in_russia',
        'qty_issued_products',
      ],
      'Received products': [
        'name',
        'order_id',
        'status',
        'link',
        'delivery_qty',
        'price',
        'currency',
        'supplier_name',
        'deliveries_id',
        'shipment_id',
        'customer_cargo_id',
        'storehouse_name',
        'storage_cell_name',
        'responsible',
        'co_executors',
        'images',
      ],
      Deliveries: [
        'order_id',
        'client_name',
        'track_number',
        'status',
        'total_amount',
        'responsible',
        'co_executors',
      ],
      Shipments: [
        'client_name',
        'type_logistics',
        'status_translate',
        'invoice_number',
        'weight_in_kg',
        'volume_in_m3',
        'number_of_seats',
        'responsible',
      ],
      'Customer cargo list': [
        'client_name',
        'content',
        'status',
        'invoice_number',
        'responsible',
      ],
      Storehouses: ['name', 'address'],
      Shelvings: [
        'name',
        'storehouse_name',
        'storage_cell_free',
        'storage_cell_not_free',
      ],
      'Storage cells': ['number', 'shelving_name', 'free'],
      'Unidentified cargo list': ['comment', 'invoice_number', 'status'],
      'Unidentified deliveries': [
        'track_number',
        'comment',
        'status',
        'total_weight_in_kg',
        'total_volume_in_m3',
        'actual_content',
      ],
    },
  },
  roman_assistant: {
    permissions: {
      Counterparties: {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
      },
      Orders: {
        read: true,
        read_all: true,
        create: true,
        create_delivery: false,
        edit: true,
        delete: true,
      },
      Products: {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
      },
      'Received products': {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
      },
      Deliveries: {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
      },
      Storehouses: {
        read: true,
      },
      Shelvings: {
        read: true,
      },
      'Storage cells': {
        read: true,
      },
      'Unidentified deliveries': {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
      },
      Shipments: {
        read_all: true,
        read: true,
        create: true,
        edit: true,
        delete: true,
        assign_storage_cell: false,
      },
      'Customer cargo list': {
        read_all: true,
        read: true,
        submit_closing_documents: true,
      },
    },
    fieldRestrictions: {
      Counterparties: {
        read: [
          'id',
          'name',
          'group_translate',
          'phone_number',
          'contact_person',
          'link_to_messenger',
          'responsible',
        ],
        create: [
          'name',
          'group_translate',
          'phone_number',
          'contact_person',
          'link_to_messenger',
          'responsible',
        ],
        edit: [
          'name',
          'group_translate',
          'phone_number',
          'contact_person',
          'link_to_messenger',
          'responsible',
        ],
        read_all: [
          'id',
          'name',
          'group_translate',
          'phone_number',
          'contact_person',
          'link_to_messenger',
        ],
      },
      Orders: {
        read_all: [
          'id',
          'datetime_created',
          'client_name',
          'estimated_cost_logistics',
          'final_cost_logistics',
          'completed',
        ],
        read: [
          'id',
          'client_name',
          'estimated_cost_logistics',
          'final_cost_logistics',
          'rate_cny_to_rub',
          'rate_usd_to_rub',
          'completed',
          'total_amount',
          'responsible',
          'co_executors',
        ],
        create: [
          'client_name',
          'estimated_cost_logistics',
          'rate_cny_to_rub',
          'rate_usd_to_rub',
          'responsible',
          'co_executors',
        ],
        edit: [
          'client_name',
          'estimated_cost_logistics',
          'rate_cny_to_rub',
          'rate_usd_to_rub',
          'responsible',
          'co_executors',
        ],
      },
      Products: {
        read: [
          'id',
          'name',
          'description',
          'order_id',
          'status',
          'link',
          'qty',
          'price',
          'currency',
          'supplier_name',
          'deliveries_id',
          'shipment_id',
          'customer_cargo_id',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'images',
          'qty_ordered_products',
          'qty_products_in_china',
          'qty_shipped_products',
          'qty_products_in_russia',
          'qty_issued_products',
        ],
        read_all: [
          'id',
          'client_name',
          'order_id',
          'name',
          'status_translate',
          'price_and_currency',
          'qty_with_unit',
          'delivery_qty_with_unit',
          'shipment_qty_with_unit',
          'customer_cargo_qty_with_unit',
          'supplier_name',
          'qty_ordered_products',
          'qty_products_in_china',
          'qty_shipped_products',
          'qty_products_in_russia',
          'qty_issued_products',
        ],
        create: [
          'name',
          'description',
          'order_id',
          'link',
          'qty',
          'price',
          'currency',
          'supplier_name',
          'responsible',
          'co_executors',
          'images',
        ],
        edit: [
          'name',
          'description',
          'qty',
          'link',
          'price',
          'currency',
          'supplier_id',
          'deliveries_id',
          'responsible',
          'co_executors',
          'images',
        ],
      },
      'Received products': {
        read: [
          'id',
          'name',
          'order_id',
          'status',
          'link',
          'delivery_qty_with_unit',
          'price',
          'currency',
          'supplier_name',
          'deliveries_id',
          'shipment_id',
          'customer_cargo_id',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'images',
        ],
        read_all: [
          'id',
          'client_name',
          'order_id',
          'name',
          'status_translate',
          'delivery_qty_with_unit',
          'price',
          'currency',
          'storehouse_name',
          'storage_cell_name',
          'supplier_name',
        ],
        edit: [
          'name',
          'status',
          'link',
          'qty',
          'price',
          'currency',
          'supplier_name',
          'deliveries_id',
          'shipment_id',
          'customer_cargo_id',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'images',
        ],
      },
      Deliveries: {
        read: [
          'id',
          'order_id',
          'client_name',
          'track_number',
          'status_tranclate',
          'total_amount',
          'media_content_acceptance',
          'responsible',
          'co_executors',
        ],
        read_all: [
          'id',
          'order_id',
          'track_number',
          'status_translate',
          'add_information',
        ],
        create: [
          'order_id',
          'supplier',
          'products',
          'track_number',
          'responsible',
          'co_executors',
        ],
        edit: [
          'order_id',
          'supplier',
          'products',
          'track_number',
          'status_tranclate',
          'responsible',
          'co_executors',
          'media_content_acceptance',
        ],
      },
      Storehouses: {
        read: ['id', 'name', 'address'],
      },
      Shelvings: {
        read_all: [
          'id',
          'name',
          'storehouse_name',
          'storage_cell_free',
          'storage_cell_not_free',
        ],
        read: ['id', 'name', 'storehouse_name'],
      },
      'Storage cells': {
        read_all: ['id', 'number', 'shelving_name', 'free_name'],
        read: ['id', 'number', 'shelving_name', 'free_name'],
      },
      'Unidentified deliveries': {
        read_all: [
          'id',
          'track_number',
          'comment',
          'status_translate',
          'total_weight_in_kg',
          'total_volume_in_m3',
          'actual_content',
        ],
        read: [
          'id',
          'track_number',
          'status_translate',
          'total_weight_in_kg',
          'total_volume_in_m3',
          'actual_content',
          'media_content_acceptance',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
        ],
        create: [
          'track_number',
          'total_weight_in_kg',
          'total_volume_in_m3',
          'actual_content',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'media_content_acceptance',
        ],
        edit: [
          'track_number',
          'status_translate',
          'total_weight_in_kg',
          'total_volume_in_m3',
          'actual_content',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'media_content_acceptance',
        ],
      },
      Shipments: {
        read_all: [
          'id',
          'client_name',
          'type_logistics_translate',
          'status_translate',
          'invoice_number',
          'weight_in_kg',
          'volume_in_m3',
          'number_of_seats',
          'responsible_names',
          'add_information',
        ],
        read: [
          'id',
          'order_id',
          'type_logistics_translate',
          'packing_translate',
          'status_translate',
          'total_amount',
          'weight_in_kg',
          'volume_in_m3',
          'number_of_seats',
          'storehouse_name',
          'storage_cell_name',
          'responsible',
          'co_executors',
          'qr_code',
          'logistics_documents',
          'media_content',
        ],
        create: [
          'order_id',
          'products',
          'packing_translate',
          'responsible',
          'co_executors',
        ],
        edit: [
          'order_id',
          'product',
          'packing_translate',
          'responsible',
          'co_executors',
          'weight_in_kg',
          'volume_in_m3',
          'storehouse_name',
          'storage_cell_name',
          'logistics_documents',
          'media_content',
        ],
      },
      'Customer cargo list': {
        read_all: [
          'id',
          'client_name',
          'content',
          'status_translate',
          'invoice_number',
          'responsible_names',
          'add_information',
        ],
        read: [
          'id',
          'order_id',
          'shipment_id',
          'content',
          'status_translate',
          'total_amount',
          'weight_in_kg',
          'volume_in_m3',
          'storehouse_name',
          'storage_cell_name',
          'invoice_documents',
          'media_content',
          'responsible',
          'co_executors',
        ],
      },
    },
    fieldFilters: {
      Counterparties: [
        'name',
        'group',
        'phone_number',
        'contact_person',
        'link_to_messenger',
        'responsible',
      ],
      Orders: [
        'client_name',
        'estimated_cost_logistics',
        'final_cost_logistics',
        'rate_cny_to_rub',
        'rate_usd_to_rub',
        'completed',
        'responsible',
        'co_executors',
      ],
      Products: [
        'name',
        'description',
        'order_id',
        'status',
        'link',
        'qty',
        'price',
        'currency',
        'supplier_name',
        'deliveries_id',
        'shipment_id',
        'customer_cargo_id',
        'storehouse_name',
        'storage_cell_name',
        'responsible',
        'co_executors',
        'qty_ordered_products',
        'qty_products_in_china',
        'qty_shipped_products',
        'qty_products_in_russia',
        'qty_issued_products',
      ],
      'Received products': [
        'name',
        'order_id',
        'status',
        'link',
        'delivery_qty',
        'price',
        'currency',
        'supplier_name',
        'deliveries_id',
        'shipment_id',
        'customer_cargo_id',
        'storehouse_name',
        'storage_cell_name',
        'responsible',
        'co_executors',
        'images',
      ],
      Deliveries: [
        'order_id',
        'client_name',
        'track_number',
        'status',
        'total_amount',
        'responsible',
        'co_executors',
      ],
      Shipments: [
        'client_name',
        'type_logistics',
        'status_translate',
        'invoice_number',
        'weight_in_kg',
        'volume_in_m3',
        'number_of_seats',
        'responsible',
      ],
      'Customer cargo list': [
        'client_name',
        'content',
        'status',
        'invoice_number',
        'responsible',
      ],
      Storehouses: ['name', 'address'],
      Shelvings: [
        'name',
        'storehouse_name',
        'storage_cell_free',
        'storage_cell_not_free',
      ],
      'Storage cells': ['number', 'shelving_name', 'free'],
      'Unidentified cargo list': ['comment', 'invoice_number', 'status'],
      'Unidentified deliveries': [
        'track_number',
        'comment',
        'status',
        'total_weight_in_kg',
        'total_volume_in_m3',
        'actual_content',
      ],
    },
  },
};

export default roleAccess;
